<template>
  <HomePage />
</template>

<script>
import HomePage from "../components/Home.component.vue";

export default {
  name: "Home",

  components: {
    HomePage,
  },
};
</script>
