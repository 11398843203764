<template>
  <v-container>
    <v-card
      class="mx-auto mt-3"
      max-width="344"
      outlined
      v-for="index in (0, 2)"
      :key="index--"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">{{ names[index] }} {{ numbers[index].lastOnlineTime}} </div>
          <v-list-item-title class="text-subtitle-1 mb-1">
            {{ today }}
          </v-list-item-title>
          <v-list-item-subtitle class="my-2 font-weight-black text-h5"
            >Cijfer: {{ numbers[index].number }}</v-list-item-subtitle
          >
          <v-list-item-subtitle class="mt-0 text-caption"
            >Last updated: {{ numbers[index].time }}</v-list-item-subtitle
          >
        </v-list-item-content>

        <v-list-item-avatar tile size="80" :color="userColor[index]">
          <v-img :src="_self.profileImages[index]" v-if="imageLoading"></v-img>
          <v-skeleton-loader
            v-if="!imageLoading"
            type="table-heading, list-item-two-line, image, table-tfoot"
          ></v-skeleton-loader>
        </v-list-item-avatar>
      </v-list-item>
    </v-card>

    <v-card class="mx-auto mt-3" max-width="344" outlined>
      <v-card-title class="text-h5"> Upcomming Events </v-card-title>

      <v-divider></v-divider>
      <v-skeleton-loader
        v-if="!loadingDone"
        type="card-heading, list-item-two-line"
        class="mb-0"
      ></v-skeleton-loader>
      <div v-if="loadingDone">
        <div v-if="firstEvent.title != null">
          <v-card-title class="font-weight-black text-h5" height="10px">{{
            firstEvent.title
          }}</v-card-title>
          <v-card-subtitle v-if="firstEvent.people == null"
            >Date: {{ firstEvent.date }}</v-card-subtitle
          >
          <v-card-subtitle v-if="firstEvent.people != null"
            >Date: {{ firstEvent.date }} <br />Who:
            {{ firstEvent.people }}</v-card-subtitle
          >
        </div>

        <div v-if="secondEvent.title != null">
          <v-divider></v-divider>
          <v-card-title class="font-weight-black text-h5" height="10px">{{
            secondEvent.title
          }}</v-card-title>
          <v-card-subtitle v-if="secondEvent.people == null"
            >Date: {{ secondEvent.date }}</v-card-subtitle
          >
          <v-card-subtitle v-if="secondEvent.people != null"
            >Date: {{ secondEvent.date }} <br />Who:
            {{ secondEvent.people }}</v-card-subtitle
          >
        </div>
        <div v-if="thirdEvent.title != null">
          <v-divider></v-divider>
          <v-divider></v-divider>
          <v-card-title class="font-weight-black text-h5" height="10px">{{
            thirdEvent.title
          }}</v-card-title>
          <v-card-subtitle v-if="thirdEvent.people == null"
            >Date: {{ thirdEvent.date }}</v-card-subtitle
          >
          <v-card-subtitle v-if="thirdEvent.people != null"
            >Date: {{ thirdEvent.date }} <br />Who:
            {{ thirdEvent.people }}</v-card-subtitle
          >
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import firebase from "firebase";
import "firebase/firestore";
import Vue from "vue";
import VueConfetti from "vue-confetti";

Vue.use(VueConfetti);

export default {
  name: "HomePage",

  data: () => ({
    today: null,
    month: null,
    year: null,
    numbers: [
      {
        number: null,
        time: null,
        lastOnlineTime: null,
      },
      {
        number: null,
        time: null,
        lastOnlineTime: null,
      },
    ],
    userIDs: ["oAj3acuHACVAFXy8pv5hkxBarfD2", "yL1Lxkv9dlgnwh4tMR4AyYWt2483"],
    firstEvent: [{ date: null, title: null, people: null }],
    secondEvent: [{ date: null, title: null, people: null }],
    thirdEvent: [{ date: null, title: null, people: null }],
    loadingDone: false,
    imageLoading: false,
    profileImages: ["", ""],
    userColor: ["", ""],
    names: ["Floris", "Robbert"],
  }),
  mounted: function () {
    this.setUserOnline();
    this.getCurrentDate();
    this.getTodaysNumber();
    this.getEvents();
    this.getUserImages();
    this.getUserOnline();
  },

  methods: {
    getCurrentDate() {
      let date = new Date();
      this.today = moment(date).format("DD-MM-YYYY");
      this.month = moment(date).format("MM");
      this.year = moment(date).format("YYYY");
    },
    getUserImages() {
      var _self = this;
      for (let i = 0; i < _self.userIDs.length; i++) {
        var tutorialsRef = firebase
          .firestore()
          .collection("users")
          .doc(_self.userIDs[i])
          .collection("profile");
        tutorialsRef.get().then(function (snapshot) {
          snapshot.forEach((doc) => {
            var imageRef = firebase.storage().ref().child(doc.data().imageUrl);
            imageRef
              .getMetadata()
              .then((metadata) => {
                _self.profileImages[i] = metadata.downloadURLs[0];
                _self.userColor[i] = doc.data().color;
              })
              .catch((error) => {
                console.log(error);
              });
          });
        });
      }
      setTimeout(function () {
        _self.imageLoading = true;
      }, 1000);
    },

    setUserOnline(){
      const current = new Date();
      var time = moment(current).format("HH:mm:ss");

      firebase
      .firestore()
      .collection("users")
      .doc(localStorage.getItem("userID") + "/profile/preferences")
      .set({ lastonline: time}, { merge: true });
    },

    getUserOnline(){
      var _self = this;
       for (let i = 0; i < _self.userIDs.length; i++) {
        var tutorialsRef = firebase
          .firestore()
          .collection("users")
          .doc(_self.userIDs[i])
          .collection("profile")
          // .doc("prefrences");
        tutorialsRef.onSnapshot(function (snapshot) {
          snapshot.forEach((doc) => {
            _self.numbers[i].lastOnlineTime = doc.data().lastonline;
          });
        });
      }
    },

    getTodaysNumber() {
      var _self = this;
      for (let i = 0; i < _self.userIDs.length; i++) {
        var tutorialsRef = firebase
          .firestore()
          .collection("users")
          .doc(_self.userIDs[i])
          .collection("data")
          .doc(_self.year)
          .collection(parseInt(_self.month).toString());
        tutorialsRef.onSnapshot(function (snapshot) {
          snapshot.forEach((doc) => {
            if (moment(doc.data().date).format("DD-MM-YYYY") == _self.today) {
              _self.numbers[i].number = doc.data().number;
              _self.numbers[i].time = doc.data().time;
            }
          });
        });
      }
    },
    getEvents() {
      var _self = this;

      var tutorialsRef = firebase
        .firestore()
        .collection("extras")
        .doc("events")
        .collection("eventDetails")
        .orderBy("dateTime");
      tutorialsRef.onSnapshot(function (snapshot) {
        let first = 0;
        let second = 0;
        let third = 0;
        snapshot.forEach((doc) => {
          let dateTime = moment(doc.data().dateTime).format("DD-MM-YYYY");

          let dateToEpoch1 = moment(doc.data().dateTime).format("YYYY-MM-DD");
          let today = new Date();
          let dateToEpoch2 = moment(today).format("YYYY-MM-DD");

          let firstDate = new Date(dateToEpoch1).getTime();
          let secondDate = new Date(dateToEpoch2).getTime();

          if (first == 0 && firstDate >= secondDate) {
            if (firstDate == secondDate) {
              _self.firstEvent.date = "Today";
            } else {
              _self.firstEvent.date = dateTime;
            }
            _self.firstEvent.title = doc.data().title;
            if (doc.data().people != undefined) {
              let names = doc.data().people.join(", ");
              _self.firstEvent.people = names;
            }
            first++;
          } else if (first == 1 && second == 0 && firstDate >= secondDate) {
            if (firstDate == secondDate) {
              _self.secondEvent.date = "Today";
            } else {
              _self.secondEvent.date = dateTime;
            }
            _self.secondEvent.title = doc.data().title;

            if (doc.data().people != undefined) {
              let names = doc.data().people.join(", ");
              _self.secondEvent.people = names;
            }
            second++;
          } else if (
            first == 1 &&
            second == 1 &&
            third == 0 &&
            firstDate >= secondDate
          ) {
            if (firstDate == secondDate) {
              _self.thirdEvent.date = "Today";
            } else {
              _self.thirdEvent.date = dateTime;
            }
            _self.thirdEvent.title = doc.data().title;
            if (doc.data().people != undefined) {
              let names = doc.data().people.join(", ");
              _self.thirdEvent.people = names;
            }
            third++;
          }
        });
      });

      setTimeout(function () {
        _self.loadingDone = true;
        if (_self.numbers[0].number == 10 && _self.numbers[1].number == 10) {
          _self.$confetti.start({
            particlesPerFrame: 0.5,
            defaultSize: 10,
            windSpeedMax: 0.2,
            particles: [
              {
                type: "rect",
              },
              {
                type: "circle",
              },
            ],
          });
        }
      }, 1000);
    },
  },
};
</script>

<style>
.v-skeleton-loader__heading {
  margin-bottom: 0px !important;
}
</style>
